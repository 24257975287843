import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <div>Header</div>
        <img src='https://m.media-amazon.com/images/I/71FkBVXE4oL._AC_UF894,1000_QL80_.jpg' alt='ghgfh'/>
    </div>
  );
}

export default App;
